<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from '../form/form.vue';

export default {
  extends: TablePage,
  components: {
    // Modal,
    FormPage,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('bill-capitalAccount-list');
  },

  methods: {
    setColumn(v) {
      const rows = v;
      if (rows.field === 'customerName') { // 客户名称
        rows.isSearch = true;
        rows.apiUrl = '/mdm/mdmCustomerMsgExtController/pageList';
        rows.paramsName = 'customerName';
        rows.optionsKey = {
          label: 'customerName',
          value: 'customerName',
        };
      }
      if (rows.field === 'companyName') { // 公司主体
        rows.type = 'select';
        rows.apiUrl = '/mdm/companyInfo/companyPageList';
        rows.isSearch = true;
        rows.optionsKey = {
          label: 'companyName',
          value: 'companyName',
        };
        rows.paramsName = 'companyName';
      }
      return rows;
    },

    modalClick({
      val,
      row,
    }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        ruleForm: '', // 数据code
        formShow: true,
      };
      switch (val.code) {
        case 'add':
          formPageArg.drawerTitle = '新增';
          break;
        case 'view':
          formPageArg.drawerTitle = '查看';
          formPageArg.ruleForm = row;
          break;
        case 'edit':
          formPageArg.drawerTitle = '编辑';
          formPageArg.ruleForm = row;
          break;
        default:
          formPageArg.drawerTitle = '新增';
      }
      this.createFormPage(formPageArg);
    },
    createFormPage(arg) {
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
