<template>
  <!-- 弹窗form -->
  <el-drawer
    custom-class="form_drawer"
    :title="drawerTitle"
    size="80%"
    :visible.sync="formShow"
    @closeDrawer="closeDrawer"
    :modal-append-to-body="false"
  >
    <!-- 表单 -->
    <DrawerComponent
      @colse="formShow = false"
      :button="drawerButton"
      :loading="loading"
      @searchData="searchData"
      :drawerConfig="drawerConfig"
    ></DrawerComponent>
  </el-drawer>
</template>

<script>
import DrawerComponent from '../../../../compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  components: {
    DrawerComponent,
  },
  data() {
    return {
      id: '',
      loading: false,
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '查看',
      drawerButton: { show: false },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: {},
          formConfig: { // 弹窗表单配置
            disabled: true,
            items: [
              [{
                label: '单据编号',
                key: 'erpCode',
              }, {
                负责人: '负责人',
                key: 'companyCode',
                show: false,
              }, {
                label: '客户名称',
                key: 'customerName',
              }, {
                label: '公司主体',
                key: 'companyName',
              }, {
                label: '账户金额',
                key: 'accountAmount',
              }, {
                label: '冻结金额',
                key: 'freezeAmount',
              }, {
                label: '可用金额',
                key: 'usableAmount',
              }, {
                label: '包材冻结金额',
                key: 'packageFreezeAmount',
              }, {
                label: '信用金额',
                key: 'creditAmount',
              }, {
                label: '可用信用金额',
                key: 'creditUsableAmount',
              }, {
                label: '客户账户',
                key: 'accountName',
              }, {
                label: '账户状态',
                key: 'customerName',
              }, {
                label: '备注',
                key: 'remarks',
                show: false,
              }],
            ],
          },
        }, {
          title: '资金明细',
          type: 'table',
          tableConfig: {
            rows: [
              {
                label: '编号',
                prop: 'dealCode',
              }, {
                label: '经销商',
                prop: 'customerName',
              }, {
                label: '公司主体',
                prop: 'companyName',
              }, {
                label: '交易类型',
                prop: 'dealType',
              }, {
                label: '期初金额',
                prop: 'initAmount',
              }, {
                label: '交易金额',
                prop: 'dealAmount',
              }, {
                label: '交易账户',
                prop: 'accountName',
              }, {
                label: '期末金额',
                prop: 'endAmount',
              }, {
                label: '修改时间',
                prop: 'updateDate',
              }, {
                label: '创建时间',
                prop: 'createDate',
              },
            ],
          },
          tableData: [],
          pagination: {
            pageNum: 1,
            pageSize: 4,
            pageSizes: [4],
          },
        },
      ],
    };
  },

  created() {
    this.drawerConfig[0].ruleForm = { ...this.ruleForm };
    this.searchDetail();
  },

  methods: {
    searchData(val) {
      this.drawerConfig[1].pagination.pageNum = val.pageNum;
      this.drawerConfig[1].pagination.pageSize = 4;
      this.drawerConfig[1].pagination.pageSizes = [4];
      this.searchDetail();
    },
    /**
     * 查询详情
     */
    async searchDetail() {
      const { result } = await this.$http.post('/dms/captitalPoolController/useInfoPageList',
        {
          customerCode: this.ruleForm.customerCode,
          companyCode: this.ruleForm.companyCode,
          pageNum: this.drawerConfig[1].pagination.pageNum,
          pageSize: this.drawerConfig[1].pagination.pageSize,
        });
      this.drawerConfig[1].tableData = result.data;
      this.drawerConfig[1].pagination.total = result.count;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}
.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}
.slot-body {
  display: flex;
  div {
    display: flex;
    align-items: center;
    flex-grow: 2;
    span {
      flex-shrink: 0;
      margin-right: 5px;
    }
    input {
      width: 150px;
    }
  }
}
</style>
